<template>
    <v-autocomplete
        v-model="model"
        :items="items"
        :loading="isLoading || loading"
        :disabled="disabled"
        :readonly="readonly"
        :clearable="!readonly"
        :search-input.sync="search"
        :hide-details="hideDetails"
        @input="stopSearch"
        @change="emitOnChange"
        hide-no-data
        hide-selected
        item-text="full_name"
        item-value="_id"
        no-data-text="No Team Members in Location"
        label="Team Members"
        chips
        small-chips
        deletable-chips
        multiple
        cache-items
        outlined
        placeholder="Start typing to search"
    >
        <template v-slot:prepend-inner>
            <v-icon color="primary">mdi-account-search</v-icon>
        </template>
    </v-autocomplete>
</template>

<script>
export default {
    name: "SearchShareableUsers",
    props: {
        value: {
            type: Array,
            default: () => [],
        },
        initialEntries: {
            type: Array,
            default: () => null
        },
        loading: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        hideDetails: {
            type: Boolean,
            default: false,
        }
    },
    data: () => ({
        api: new formHelper(),
        descriptionLimit: 60,
        entries: [],
        isLoading: false,
        search: null,
        preventSearch: false,
        maxDisplay: 3,
    }),
    computed: {
        model: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            }
        },
        items() {
            return this.entries.map(entry => {
                const full_name = entry.full_name.length > this.descriptionLimit
                    ? entry.full_name.slice(0, this.descriptionLimit) + '...'
                    : entry.full_name

                return Object.assign({}, entry, {full_name})
            })
        },
        globalLocation() {
            return this.$root.globalLocation
        },
        globalLocationId() {
            if (!this.globalLocation) {
                return null
            }

            return this.globalLocation._id
        }
    },
    watch: {
        search: _.debounce(function (name) {
            // Do not search if query string is empty
            if (!name || (typeof name === 'string' && name.trim().length === 0)) {
                return
            }

            // Do not search if item has been selected from the menu
            if (this.preventSearch) {
                this.preventSearch = false
                if (this.entries.some(user => user.full_name === name)) {
                    return
                }
            }

            this.queryByName(name)
        }, 500)
    },
    methods: {
        stopSearch() {
            this.preventSearch = true
        },
        queryByName(name_query) {
            this.isLoading = true
            this.api.get(`/report-automation/search-shareable-user/${this.globalLocationId}/${name_query}`).then(({data}) => {
                if (Array.isArray(data)) {
                    this.entries = Array.from(data)
                }
            }).catch(() => {
            })
                .finally(() => {
                    this.isLoading = false
                })
        },
        emitOnChange() {
            this.search = null
            this.$emit('onChange')
        }
    },
    mounted() {
        if (this.model && this.initialEntries) {
            this.entries = Array.from(this.initialEntries)
        }
    }
}
</script>

<style scoped>

</style>
